/* eslint-disable no-bitwise */
export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET;
export const JWT_EXPIRES_IN = 3600 * 24 * 2;

export const sign = (payload, privateKey, header) => {
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));
  const signature = btoa(
    Array.from(encodedPayload)
      .map((item, key) =>
        String.fromCharCode(
          item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)
        )
      )
      .join('')
  );

  return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const decode = (token) => {
  const [, encodedPayload, signature] = token.split('.');
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (payload.exp < now) {
    throw new Error('Expired token');
  }

  const verifiedSignature = btoa(
    Array.from(encodedPayload)
      .map((item, key) =>
        String.fromCharCode(
          item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0)
        )
      )
      .join('')
  );

  if (verifiedSignature !== signature) {
    throw new Error('Invalid signature');
  }

  return payload;
};

export const verify = (token) => {
  const [, encodedPayload,] = token.split('.');
  const payload = JSON.parse(atob(encodedPayload));
  const now = Date.now() / 1000;

  if (payload.exp < now || !payload.is_active) {
    console.error('The token is expired!');
    return false;
  }

  return payload;
};
