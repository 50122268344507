import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Account

const LoginBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);

const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Basic'))
);

const Payment = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Payment'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const ResetPassword = Loader(
  lazy(() => import('src/content/pages/Auth/ResetPassword'))
);

const accountRoutes = [
  {
    path: 'login-basic',
    element: <LoginBasic />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },

  {
    path: 'register-basic',
    children: [
      {
        path: '/',
        element: <Navigate to="showCompanyName" replace />
      },
      {
        path: ':showCompanyName',
        element: <RegisterBasic />
      },
      {
        path: '/payment/:userUid',
        element: <Payment />
      },
    ]
  },
  {
    path: '/reset/',
    element: <ResetPassword />
  },
];

export default accountRoutes;
