import { mock } from 'src/utils/axios';
import wait from 'src/utils/wait';
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from 'src/utils/jwt';
import randomId from 'src/utils/randomId';

const users = [
  {
    id: '1',
    email: 'manager@exemple.com',
    first_name: 'Eric',
    last_name: 'Schmidt',
    password: 'manager@exemple.com',
    is_admin: true,
    nb_available_seats: 10,
    nb_used_seats: 2
  },
  {
    id: '2',
    email: 'sales@exemple.com',
    first_name: 'Thomas',
    last_name: 'Saran',
    password: 'sales@exemple.com',
    is_admin: false,
    nb_available_seats: 0,
    nb_used_seats: 0
  }
];

mock.onPost(`${process.env.REACT_APP_JWT_API_URL}/api/users/login`).reply(async (config) => {
  await wait(1000);

  try {
    const { email, password } = JSON.parse(config.data);

    const user = users.find((_user) => _user.email === email);

    if (!user || user.password !== password) {
      return [
        400,
        { message: 'Verify that your email and password are correct' }
      ];
    }

    const accessToken = sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [
      200,
      {
        accessToken,
        user: {
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          password: user.password,
          is_admin: user.is_admin,
          nb_available_seats: user.nb_available_seats,
          nb_used_seats: user.nb_used_seats
        }
      }
    ];
  } catch (err) {
    console.error('Error: ', err);
    return [500, { message: 'Encountered a server error' }];
  }
});

mock.onPost(`${process.env.REACT_APP_JWT_API_URL}/api/users`).reply(async (config) => {
  await wait(1000);

  try {
    const { email, firstName, lastName, password } = JSON.parse(config.data);

    let user = users.find((_user) => _user.email === email);

    if (user) {
      return [400, { message: 'This user already exists' }];
    }

    user = {
      id: randomId(),
      email,
      first_name: firstName,
      last_name: lastName,
      is_admin: false,
      password,
      nb_available_seats: 0,
      nb_used_seats: 0
    };

    users.push(user);

    const accessToken = sign({ userId: user.id }, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    return [
      200,
      {
        accessToken,
        user: {
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          password: user.password,
          is_admin: user.is_admin,
          nb_available_seats: user.nb_available_seats,
          nb_used_seats: user.nb_used_seats
        }
      }
    ];
  } catch (err) {
    console.error('Error: ', err);
    return [500, { message: 'Encountered a server error' }];
  }
});

mock.onGet(`${process.env.REACT_APP_JWT_API_URL}/api/users/me`).reply((config) => {
  try {
    const { Authorization } = config.headers;

    if (!Authorization) {
      return [401, { message: 'Auth token is missing' }];
    }

    const accessToken = Authorization.split(' ')[1];
    const { userId } = decode(accessToken);
    const user = users.find((_user) => _user.id === userId);

    if (!user) {
      return [401, { message: 'Invalid auth token' }];
    }

    return [
      200,
      {
        user: {
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          password: user.password,
          is_admin: user.is_admin,
          nb_available_seats: user.nb_available_seats,
          nb_used_seats: user.nb_used_seats
        }
      }
    ];
  } catch (err) {
    console.error('Error: ', err);
    return [500, { message: 'Encountered a server error' }];
  }
});
