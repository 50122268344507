import { mock } from 'src/utils/axios';

let today = new Date();
let start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
let end = new Date(today.getFullYear(), today.getMonth(), 0);

function getRandomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

let spiffs = [
    {
        id: 1,
        deal_name: 'Awesome Company',
        owner_id: 'Moi',
        deal_value_cents: 50000,
        earned_spiff_value_cents: 500,
        deal_closed: getRandomDate(start, end).toISOString(),
        stage: 'appointmentscheduled',
    },
    {
        id: 2,
        deal_name: 'Innovative SAS',
        owner_id: 'Moi',
        deal_value_cents: 70000,
        earned_spiff_value_cents: 500,
        deal_closed: getRandomDate(start, end).toISOString(),
        stage: 'appointmentscheduled',
    },
    {
        id: 3,
        deal_name: 'Incredible SAS',
        owner_id: 'Moi',
        deal_value_cents: 100000,
        earned_spiff_value_cents: 1000,
        deal_closed: getRandomDate(start, end).toISOString(),
        stage: 'appointmentscheduled',
    },
    {
        id: 4,
        deal_name: 'Unstoppable',
        owner_id: 'Moi',
        deal_value_cents: 100000,
        earned_spiff_value_cents: 0,
        deal_closed: getRandomDate(start, end).toISOString(),
        stage: 'closedlost',
    },
];

mock.onGet(`${process.env.REACT_APP_JWT_API_URL}/api/users/me/deals`).reply(() => {
    return [200, spiffs];
});