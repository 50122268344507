import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import AdminRoute from 'src/components/AdminRoute';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Management

const UserMe = Loader(
  lazy(() => import('src/content/management/Users/me'))
);
const Pipelines = Loader(lazy(() => import('src/content/management/Pipelines')));
const SinglePipeline = Loader(
  lazy(() => import('src/content/management/Templates/single'))
);
const Spiffs = Loader(lazy(() => import('src/content/management/Spiffs')));
const SingleSpiff = Loader(
  lazy(() => import('src/content/management/Spiffs/single/index'))
);
const Templates = Loader(lazy(() => import('src/content/management/Templates')));
const SingleTemplate = Loader(
  lazy(() => import('src/content/management/Templates/single'))
);
const CreateNewTemplate = Loader(lazy(() => import('src/content/management/Templates/CreateNew')));

const CreateNewPipeline = Loader(lazy(() => import('src/content/management/Pipelines/CreateNew')));

const Payouts = Loader(lazy(() => import('src/content/management/Payouts')));


const managementRoutes = [
  {
    path: '/',
    element: <Navigate to="users" replace />
  },
  {
    path: 'users',
    children: [
      {
        path: 'me',
        element: <UserMe />
      },
    ]
  },
  {
    path: 'pipelines',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (<AdminRoute>
          <Pipelines />
        </AdminRoute>)
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':pipelineId',
            element: <SinglePipeline />
          }
        ]
      },
      {
        path: 'create',
        children: [
          {
            path: '/',
            element: <CreateNewPipeline />
          },
          {
            path: ':crmUid',
            element: <CreateNewPipeline />
          }
        ]
      },     
    ]
  },
  {
    path: 'spiffs',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Spiffs />
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':spiffUid',
            element: <SingleSpiff />
          }
        ]
      }
    ]
  },
  {
    path: 'templates',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (<AdminRoute>
          <Templates />
        </AdminRoute>)
      },
      {
        path: 'new',
        element: (<AdminRoute>
          <CreateNewTemplate />
        </AdminRoute>)
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="tpl_e52352e9-7014-43b9-b2d4-90eb3ae05e26" replace />
          },
          {
            path: ':templateUid',
            element: <SingleTemplate />
          }
        ]
      }
    ]
  },
  {
    path: 'payouts',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Payouts />
      }
    ]
  },
];

export default managementRoutes;
